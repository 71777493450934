import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import OfflineService from "@/core/services/OfflineService";
import { notify } from "@/core/helpers/globalJaya";
import ApiService from "@/core/services/ApiService";
import { videoObject } from "@/store/modules/VideosModule";

export interface Factor {
  id: number;
  title: string;
  short_title: string;
  perimeter: string;
  number: string;
  video: videoObject;
  levels: Array<FactorLevel>;
  biodiversity_stakes: string;
  notation_factors: string;
  solution_examples: string;
  useful_resources: Array<string>;
}

export interface FactorLevel {
  id: number;
  level: number;
  criteria: string;
}

export interface Diagnostic {
  id: number;
  is_real: boolean | null;
  finished: boolean;
  exploitation: number;
  exploitation_id: number;
  date_created: string;
  date_evaluated: string;
  results: Array<DiagnosticResult>;
}

export interface DiagnosticResult {
  id: number;
  level: number;
  level_details: {
    factor: number;
    level: number;
  };
}

export interface FactorLevelES {
  active: boolean;
  justification: string;
  factor_level: { id: number; level: number };
}

export interface EcosystemicService {
  id: number | undefined;
  name: string;
  description: string;
  interest: string;
  representativeness: string;
  not_estimated_practices: Array<Array<string>>;
  additional_resources: Array<Array<string>>;
  max_score: number;
  factor_levels: Array<FactorLevelES>;
  short_name: string;
}

export interface EcosystemicServicesCategory {
  id: number;
  name: string;
  services: Array<EcosystemicService>;
}

export interface FactorGroup {
  id: number;
  title: string;
}

@Module
export default class DiagnosticsModule extends VuexModule {
  factors = [] as Array<Factor>;
  factorGroups = [] as Array<FactorGroup>;
  diagnostics = [] as Array<Diagnostic>;
  ecosystemicServicesCategories = [] as Array<EcosystemicServicesCategory>;

  ecosystemicServiceFieldExplanation = {
    interest: [
      "Intêret",
      "Une combinaison de : " +
        "<br>(i) fréquents dans le cadre de PSE - Paiements pour Services Ecosystémiques, " +
        "<br>(ii) bénéfique au cadre de vie, " +
        "<br>(iii) utile à la production agricole.",
    ],
    representativeness: [
      "Représentativité",
      "Capacité du questionnaire AgriBEST à prendre en compte l'ensemble des pratiques et paramètres nécessaires à" +
        " l'évaluation de ce service éco-systémique",
    ],
    non_estimated_practices: [
      "Pratiques et paramètres non estimés",
      "Pratiques et paramètres impossibles à estimer avec le questionnaire actuel, ayant l'effet suivant sur le service écosystémique :" +
        "<br>Tout le temps positif : <strong class='fs-3 fw-boldest'>+</strong>" +
        "<br>Dépendant du contexte : <strong class='fs-3 fw-boldest'>±</strong>" +
        "<br>Tout le temps négtif : <strong class='fs-3 fw-boldest'>-</strong>",
    ],
  };

  get getFactors() {
    return this.factors.sort((a, b) => {
      const AFactor = Number(a.number.split("F")[1].split("-")[0]);
      const BFactor = Number(b.number.split("F")[1].split("-")[0]);
      if (AFactor < BFactor) {
        return -1;
      } else if (AFactor > BFactor) {
        return 1;
      } else {
        const AFactorTwo = Number(a.number.split("F")[1].split("-")[1]);
        const BFactorTwo = Number(b.number.split("F")[1].split("-")[1]);
        if (AFactorTwo < BFactorTwo) {
          return -1;
        } else if (AFactorTwo > BFactorTwo) {
          return 1;
        }
        return 0;
      }
    });
  }

  get getDiagnostics() {
    return this.diagnostics;
  }

  get getFactorGroups() {
    return this.factorGroups;
  }

  get getEcosystemicServicesCategories() {
    return this.ecosystemicServicesCategories;
  }

  get getEcosystemicServiceFieldExplanation() {
    return this.ecosystemicServiceFieldExplanation;
  }

  //only for développment purposes
  @Mutation
  [Mutations.SET_FACTOR](payload) {
    this.factors.push(payload);
  }
  @Mutation
  [Mutations.SET_FACTOR_GROUPS](payload) {
    this.factorGroups = payload;
  }
  @Mutation
  [Mutations.SET_DIAGNOSTIC](payload) {
    this.diagnostics.push(payload);
  }

  @Mutation
  [Mutations.EDIT_DIAGNOSTIC](payload) {
    const editingDiagnostic = this.diagnostics.find(
      (diagnostic) => diagnostic.id === payload.id
    );
    if (editingDiagnostic) {
      if (payload.exploitation) {
        editingDiagnostic.exploitation = payload.exploitation;
      }
      if (payload.is_real) {
        editingDiagnostic.is_real = payload.is_real;
      }
      if (payload.finished) {
        editingDiagnostic.finished = payload.finished;
      }
      if (payload.date_evaluated) {
        editingDiagnostic.date_evaluated = payload.date_evaluated;
      }
    }
  }

  @Mutation
  [Mutations.SET_DIAGNOSTIC_RESULT](payload) {
    const editingDiagnostic = this.diagnostics.find(
      (diagnostic) => diagnostic.id === payload.diagnostic
    );
    if (editingDiagnostic) {
      editingDiagnostic.results.push({
        id: payload.id,
        level: payload.level,
        level_details: {
          factor: payload.level_details.factor,
          level: payload.level_details.level,
        },
      });
    }
  }

  @Mutation
  [Mutations.REMOVE_DIAGNOSTIC_RESULT](payload) {
    const editingDiagnostic = this.diagnostics.find(
      (diagnostic) => diagnostic.id === payload.diagnostic
    );
    if (editingDiagnostic) {
      const resultIndex = editingDiagnostic.results.findIndex(
        (result) => result.id === payload.id
      );
      editingDiagnostic.results.splice(resultIndex, 1);
    }
  }

  @Mutation
  [Mutations.REMOVE_DIAGNOSTIC](diagnosticId) {
    this.diagnostics = this.diagnostics.filter(
      (diagnostic) => diagnostic.id !== diagnosticId
    );
  }

  @Mutation
  [Mutations.SET_ECOSYSTEMIC_SERVICES_CATEGORIES](payload) {
    this.ecosystemicServicesCategories.push(payload);
  }

  @Action
  [Actions.FETCH_QUIZ]() {
    return new Promise((resolve, reject) => {
      OfflineService.fetchObject("quiz")
        .then((response) => {
          for (const quiz of response) {
            const factors = quiz.factors;
            this.factors.splice(0, this.factors.length);
            for (const factor of factors) {
              this.context.commit(Mutations.SET_FACTOR, factor);
            }
          }
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible de récupérer le questionnaire";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }
  @Action
  [Actions.FETCH_FACTOR_GROUPS]() {
    return new Promise((resolve, reject) => {
      OfflineService.fetchObject("factor_groups")
        .then((response) => {
          this.context.commit(Mutations.SET_FACTOR_GROUPS, response);
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible de récupérer les catégories de facteurs";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }
  @Action
  [Actions.FETCH_DIAGNOSTICS]() {
    return new Promise((resolve, reject) => {
      OfflineService.fetchObject("diagnostics")
        .then((response) => {
          this.diagnostics.splice(0, this.diagnostics.length);
          for (const diagnostic of response) {
            this.context.commit(Mutations.SET_DIAGNOSTIC, diagnostic);
          }
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible de récupérer les diagnostics";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.ADD_DIAGNOSTIC](payload) {
    return new Promise((resolve, reject) => {
      OfflineService.addObject("diagnostics", payload)
        .then((response) => {
          this.context.commit(Mutations.SET_DIAGNOSTIC, response);
          resolve(response);
          notify({
            text: "Diagnostic créé",
            color: "success",
          });
        })
        .catch((error) => {
          const err_msg = "Impossible d'ajouter le diagnostic";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_DIAGNOSTIC](payload) {
    return new Promise((resolve, reject) => {
      OfflineService.updateObject("diagnostics", payload)
        .then((response) => {
          this.context.commit(Mutations.EDIT_DIAGNOSTIC, response);
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible de mettre à jour le diagnostic";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.DELETE_DIAGNOSTIC](payload) {
    return new Promise((resolve, reject) => {
      OfflineService.deleteObject("diagnostics", payload)
        .then((response) => {
          this.context.commit(Mutations.REMOVE_DIAGNOSTIC, payload.id);
          notify({
            text: "Diagnostic retiré",
            color: "success",
          });
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible de supprimer le diagnostic";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.ADD_DIAGNOSTIC_RESULT](payload) {
    return new Promise((resolve, reject) => {
      OfflineService.addObject("results", payload)
        .then((response) => {
          this.context.commit(Mutations.SET_DIAGNOSTIC_RESULT, response);
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible d'ajouter la réponse";
          if (error.response && error.response.data) {
            if (
              error.response.status === 400 &&
              error.response.data.detail ===
                "More than one result per factor not allowed"
            ) {
              notify({
                text: err_msg + " : une réponse existe déjà pour ce facteur",
                color: "error",
              });
              location.reload();
            } else if (
              error.response.status === 400 &&
              error.response.data.detail ===
                "Completed diagnostic can not be updated"
            ) {
              notify({
                text: err_msg + " : le diagnostic est terminé",
                color: "error",
              });
            } else {
              notify({
                text: err_msg,
                color: "error",
              });
            }
          }
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_DIAGNOSTIC_RESULT](payload) {
    return new Promise((resolve, reject) => {
      OfflineService.updateObject("results", payload)
        .then((response) => {
          this.context.commit(Mutations.REMOVE_DIAGNOSTIC_RESULT, response);
          this.context.commit(Mutations.SET_DIAGNOSTIC_RESULT, response);
          notify({
            text: "Le niveau du facteur de biodiversité a bien été modifié",
            color: "success",
            duration: 3000,
          });
          resolve(response);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.status === 400 &&
            error.response.data.detail ===
              "Completed diagnostic can not be updated"
          ) {
            notify({
              text: "Impossible de mettre à jour un diagnostic terminé",
              color: "error",
              duration: 3000,
            });
          } else {
            notify({
              text: "Erreur : Impossible de mettre à jour la réponse",
              color: "error",
              duration: 3000,
            });
          }
          reject(error);
        });
    });
  }

  @Action
  [Actions.FETCH_ECOSYSTEMIC_SERVICES_CATEGORIES]() {
    return new Promise((resolve, reject) => {
      OfflineService.fetchObject("ecosystemic_services_categories")
        .then((response) => {
          this.ecosystemicServicesCategories.splice(
            0,
            this.ecosystemicServicesCategories.length
          );
          for (const category of response) {
            this.context.commit(
              Mutations.SET_ECOSYSTEMIC_SERVICES_CATEGORIES,
              category
            );
          }
          resolve(response);
        })
        .catch((error) => {
          notify({
            text: "Erreur : Catégories de services écosystémiques non trouvées",
            color: "error",
          });
          reject(error);
        });
    });
  }
  @Action
  [Actions.FETCH_MEAN_ECOSYSTEMIC_SERVICES](payload) {
    return new Promise((resolve, reject) => {
      ApiService.query("ecosystemic_services_stats_users", { params: payload })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          notify({
            text: "La récupération des statistiques a échouée",
            color: "error",
          });
          reject(error);
        });
    });
  }
}
